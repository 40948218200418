<template>
  <CHeader id="header" position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderNav>
        <CAvatar
          class="avatar avatar-lg bg-secondary"
          :src="
            'https://ui-avatars.com/api/?color=3272B0&rounded=true&background=FFFFFF&name=' +
            spp_data.student_name
          "
        />
        <CCol style="padding-left: 20px">
          <CRow class="fw-semibold"> {{ spp_data.student_name }} </CRow>
          <CRow> Current week: Week {{ spp_data.week_cycle }}</CRow>
        </CCol>
      </CHeaderNav>
    </CContainer>
    <CContainer fluid>
      <CHeaderNav>
        <CNavItem v-for="(value, index) in spp_data.subjects" :key="index">
          <CNavLink
            :href="'#/' + this.$route.params.student + value.route"
            :disabled="value.disabled"
            @click="this.selectedPage = value.name"
            :style="{
              'font-weight': `${
                this.selectedPage === value.name ? 'bold' : 'normal'
              }`,
              'border-bottom': `${
                this.selectedPage === value.name ? '5px solid #a69164' : ''
              }`,
            }"
          >
            {{ value.name }}
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import { mapState } from 'vuex'

import { logo } from '@/assets/brand/logo'
export default {
  name: 'AppHeader',
  setup() {
    return {
      logo,
      avatar: avatar,
    }
  },
  data() {
    return {
      selectedPage: 'All',
      isLoading: true,
    }
  },
  computed: {
    ...mapState({
      spp_data: (state) => state.user.spp_data,
    }),
  },
  async mounted() {
    await this.fetchSPPData()
    this.isLoading = false
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        if (route.params.subject) {
          this.selectedPage = route.params.subject
        } else {
          this.selectedPage = 'All'
        }
      },
    },
  },
  methods: {
    async fetchSPPData() {
      await this.$store.dispatch('user/fetchSPPData', {
        studentid: this.$route.params.student,
      })
    },
  },
}
</script>
