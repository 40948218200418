import Cookies from 'js-cookie'

Cookies.set('token', process.env.VUE_APP_SPP_TOKEN)
const canvas_url = process.env.VUE_APP_CANVAS_URL
const api_base_url = process.env.VUE_APP_SPP_BASE_URL
const spp_data_api = api_base_url + 'api/student'
const spp_quiz_stats_api = api_base_url + 'api/quiz-cohort-averages'
const spp_badges_api = api_base_url + 'api/badges'
const spp_course_api = api_base_url + 'api/course'
const spp_leaderboard_api = api_base_url + 'api/badges/leaderboard'
const spp_leaderboard_cache_api = api_base_url + 'api/badges/leaderboard/cache'

export {
  canvas_url,
  spp_data_api,
  spp_quiz_stats_api,
  spp_badges_api,
  spp_course_api,
  spp_leaderboard_api,
  spp_leaderboard_cache_api,
}
