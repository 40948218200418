import axios from 'axios'
import { spp_data_api, spp_quiz_stats_api, spp_course_api } from '../../api'
import Cookies from 'js-cookie'

// state
const state = {
  spp_data: {
    student_id: '',
    student_name: '',
    term: '',
    week_cycle: '',
    subjects: [],
  },
  homework_submission: [],
  quiz_completion: [],
  homeworks: [],
  quiz_scores: [],
  subject_details: [],
  is_loading_quiz: true,
  is_loading_homework: true,
  page_length_quiz: 0,
  quiz_body: [],
  selected_subject: '',
  rankings: [],
  user_details: null,
  request: null,
  completed_quiz_percentage: 0,
  try_unsubmitted_quiz_data: null,
  graded_quiz_data: null,
}

// getters
const getters = {}

// mutations
const mutations = {
  FETCH_SPP_DATA(state, resp) {
    for (
      var i = 0;
      i < Object.keys(resp.statistics.homework_submission).length;
      i++
    ) {
      if (Object.keys(resp.statistics.homework_submission)[i] === 'English') {
        resp.statistics.homework_submission[
          Object.keys(resp.statistics.homework_submission)[i]
        ] = {
          ...resp.statistics.homework_submission[
            Object.keys(resp.statistics.homework_submission)[i]
          ],
          color: 'rgba(255, 211, 144, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
        resp.statistics.quiz_completion[
          Object.keys(resp.statistics.quiz_completion)[i]
        ] = {
          ...resp.statistics.quiz_completion[
            Object.keys(resp.statistics.quiz_completion)[i]
          ],
          color: 'rgba(255, 211, 144, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
      }
      if (
        Object.keys(resp.statistics.homework_submission)[i] === 'Mathematics'
      ) {
        resp.statistics.homework_submission[
          Object.keys(resp.statistics.homework_submission)[i]
        ] = {
          ...resp.statistics.homework_submission[
            Object.keys(resp.statistics.homework_submission)[i]
          ],
          color: 'rgba(136, 197, 255, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
        resp.statistics.quiz_completion[
          Object.keys(resp.statistics.quiz_completion)[i]
        ] = {
          ...resp.statistics.quiz_completion[
            Object.keys(resp.statistics.quiz_completion)[i]
          ],
          color: 'rgba(136, 197, 255, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
      }
      if (Object.keys(resp.statistics.homework_submission)[i] === 'Science') {
        resp.statistics.homework_submission[
          Object.keys(resp.statistics.homework_submission)[i]
        ] = {
          ...resp.statistics.homework_submission[
            Object.keys(resp.statistics.homework_submission)[i]
          ],
          color: 'rgba(161, 227, 171, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
        resp.statistics.quiz_completion[
          Object.keys(resp.statistics.quiz_completion)[i]
        ] = {
          ...resp.statistics.quiz_completion[
            Object.keys(resp.statistics.quiz_completion)[i]
          ],
          color: 'rgba(161, 227, 171, 1)',
          status:
            Object.keys(resp.subjects[Object.keys(resp.subjects)[i]].homework)
              .length > 0
              ? true
              : false,
          course: resp.subjects[Object.keys(resp.subjects)[i]].course
            ? resp.subjects[Object.keys(resp.subjects)[i]].course
            : null,
          delivery_mode: resp.subjects[Object.keys(resp.subjects)[i]]
            .delivery_mode
            ? resp.subjects[Object.keys(resp.subjects)[i]].delivery_mode
            : null,
        }
      }
    }
    state.homework_submission = resp.statistics.homework_submission
    state.quiz_completion = resp.statistics.quiz_completion
    var subjects = [
      {
        name: 'All',
        route: '',
        disabled: false,
      },
    ]
    for (var x = 0; x < Object.keys(resp.subjects).length; x++) {
      if (
        Object.keys(resp.subjects[Object.keys(resp.subjects)[x]].homework)
          .length > 0
      ) {
        subjects.push({
          name: Object.keys(resp.subjects)[x],
          route: '/' + Object.keys(resp.subjects)[x],
          disabled: false,
        })
      } else {
        subjects.push({
          name: Object.keys(resp.subjects)[x],
          route: '/' + Object.keys(resp.subjects)[x],
          disabled: true,
        })
      }
    }
    state.spp_data = {
      student_id: resp.student_id,
      student_name: resp.student_name,
      term: resp.term,
      week_cycle: resp.week_cycle,
      subjects: subjects,
    }
    state.is_loading = false
  },
  FETCH_HOMEWORK_DATA(state, resp) {
    state.homeworks = resp.homeworks
    state.quiz_body = resp.quiz_body
    state.page_length_quiz = resp.page_length_quiz
    state.subject_details = {
      ...resp.subject_details,
      name: resp.subject_details.teacher.name,
      email: resp.subject_details.teacher.email,
      user_id: resp.subject_details.teacher.user_id,
    }
    state.is_loading_homework = false
    state.quiz_scores = resp.quiz_scores
  },
  FETCH_QUIZ_STATISTICS(state, resp) {
    state.quiz_scores = resp.quiz_scores
    state.is_loading_quiz = false
  },
  FETCH_BADGE_DATA(state, resp) {
    state.is_loading_badges = false
    state.badge_data = resp.badge_data
    state.show_badges = resp.show_badges
  },
  FETCH_LEADER_BOARD(state, resp) {
    if (
      state.selected_subject === resp.selected_subject ||
      resp.page_name === 'Leaderboard' ||
      resp.page_name === 'CourseSidebar'
    ) {
      var modified_rankings = []
      var info = ''
      var cond_color = ''
      if (resp.leaderboard_data !== null) {
        var i = 0
        modified_rankings = resp.leaderboard_data.rankings
        if (
          resp.leaderboard_data.user !== undefined ||
          resp.leaderboard_data.user !== null
        ) {
          if (
            resp.leaderboard_data.user.rank >
            Object.keys(modified_rankings).length
          ) {
            for (i; i < Object.keys(modified_rankings).length; i++) {
              cond_color = '#ffffff'
              if (i === 0) {
                cond_color = '#ffeeb4'
              }
              if (i === 1) {
                cond_color = '#e6e6e6'
              }
              if (i === 2) {
                cond_color = '#f3cfb0'
              }
              if (i > 2) {
                cond_color = '#e5f1f3'
              }
              modified_rankings[Object.keys(modified_rankings)[i]] = {
                ...modified_rankings[Object.keys(modified_rankings)[i]],
                current_user: false,
                index: i,
                color: cond_color,
              }
            }
            modified_rankings.push({
              ...resp.leaderboard_data.user,
              current_user: true,
              index: i,
              color: '#8fccdb',
            })
          } else {
            for (i; i < Object.keys(modified_rankings).length; i++) {
              cond_color = '#ffffff'
              if (i === 0) {
                cond_color = '#ffeeb4'
              }
              if (i === 1) {
                cond_color = '#e6e6e6'
              }
              if (i === 2) {
                cond_color = '#f3cfb0'
              }
              if (i > 2) {
                cond_color = '#e5f1f3'
              }
              modified_rankings[Object.keys(modified_rankings)[i]] = {
                ...modified_rankings[Object.keys(modified_rankings)[i]],
                current_user:
                  modified_rankings[Object.keys(modified_rankings)[i]]
                    .login_id === resp.leaderboard_data.user.login_id
                    ? true
                    : false,
                index: i,
                color: cond_color,
              }
            }
          }
        } else {
          for (i; i < Object.keys(modified_rankings).length; i++) {
            cond_color = '#e5f1f3'
            if (i === 0) {
              cond_color = '#ffeeb4'
            }
            if (i === 1) {
              cond_color = '#e6e6e6'
            }
            if (i === 2) {
              cond_color = '#f3cfb0'
            }
            if (i > 2) {
              cond_color = '#e5f1f3'
            }
            modified_rankings[Object.keys(modified_rankings)[i]] = {
              ...modified_rankings[Object.keys(modified_rankings)[i]],
              current_user: false,
              index: i,
              color: cond_color,
            }
          }
        }
        state.user = resp.leaderboard_data.user
        info =
          resp.leaderboard_data.info !== undefined
            ? resp.leaderboard_data.info
            : ''
        state.user_details = resp.leaderboard_data.user
      }
      state.info = info
      state.rankings = modified_rankings
      state.is_loading_leaderboard = false
      state.show_leaderboard = resp.show_leaderboard
    }
  },
  FETCH_STUDENT_QUIZ_STATISTICS(state, resp) {
    var completed_quiz_percentage = 0
    var modified_graded_data = []
    var modified_try_quiz_data = []

    if (resp.quiz_statistics !== null) {
      completed_quiz_percentage = Math.round(
        (resp.quiz_statistics.completed / resp.quiz_statistics.total) * 100,
      )
    }
    if (resp.graded_quiz_data !== null) {
      var quiz_percentage = 0
      for (var i = 0; i < Object.keys(resp.graded_quiz_data).length; i++) {
        var key = Object.keys(resp.graded_quiz_data)[i]
        var score =
          resp.graded_quiz_data[key].score !== null
            ? resp.graded_quiz_data[key].score
            : 0
        var points_possible = resp.graded_quiz_data[key].points_possible
        quiz_percentage = Math.round((score / points_possible) * 100)
        modified_graded_data.push({
          ...resp.graded_quiz_data[key],
          quiz_percentage: quiz_percentage,
        })
      }
    }

    if (resp.unsubmitted_quiz_data !== null) {
      const keys = Object.keys(resp.unsubmitted_quiz_data)
      const shuffledKeys = keys.sort(() => Math.random() - 0.5)
      const randomKeys = shuffledKeys.slice(0, 3)
      randomKeys.forEach((key) => {
        modified_try_quiz_data.push(resp.unsubmitted_quiz_data[key])
      })
    }

    state.completed_quiz_percentage = completed_quiz_percentage
    state.try_unsubmitted_quiz_data = modified_try_quiz_data
    state.graded_quiz_data = modified_graded_data
    state.is_loading_side_bar = false
    state.show_side_bar = resp.show_side_bar
  },
}

// actions
const actions = {
  async fetchSPPData({ commit, dispatch }, data) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + Cookies.get('token')
    return await axios
      .get(`${spp_data_api}/${data.studentid}`)
      .then((response) => {
        if (data.selected_subject) {
          var homework = response.data.subjects[data.selected_subject].homework
          var week_cycle = response.data.week_cycle
          var homework_body = []
          for (var a = 0; a < Object.keys(homework).length; a++) {
            var workflow_states = [
              { state: 'graded', color: '#63CFE3' },
              { state: 'pending_review', color: '#63CFE3' },
              { state: 'submitted', color: '#63CFE3' },
              { state: 'unsubmitted', color: '#E3D663' },
            ]
            var workflow_color, state
            var hw_week_cycle = parseInt(
              homework[Object.keys(homework)[a]].name.split(' ')[1],
            )
            var hw_type = homework[Object.keys(homework)[a]].name.split(' ')[0]

            if (hw_week_cycle <= week_cycle) {
              workflow_states.forEach((workflow_state) => {
                if (
                  workflow_state.state ===
                  homework[Object.keys(homework)[a]].workflow_state
                ) {
                  workflow_color = workflow_state.color
                  state = false
                }
              })
            } else {
              workflow_color = '#E0E0E0'
              state = true
            }
            homework_body.push({
              ...homework[Object.keys(homework)[a]],
              color: workflow_color,
              disabled: state,
              hw_week_cycle: hw_week_cycle,
              is_ppp: hw_type === 'PPP' ? true : false,
            })
          }
          homework_body.sort(function (a, b) {
            return a.hw_week_cycle - b.hw_week_cycle
          })

          var quizzes = response.data.subjects[data.selected_subject].quiz
          var quiz_body = []
          for (var x = 0; x < Object.keys(quizzes).length; x++) {
            quiz_body.push({
              quiz_id: quizzes[Object.keys(quizzes)[x]].id,
              assignment_id: quizzes[Object.keys(quizzes)[x]].assignment_id,
              score:
                quizzes[Object.keys(quizzes)[x]].score === null
                  ? 0
                  : Math.round(
                      (quizzes[Object.keys(quizzes)[x]].score /
                        quizzes[Object.keys(quizzes)[x]].points_possible) *
                        100,
                    ),
              title: quizzes[Object.keys(quizzes)[x]].title,
              quiz_url: quizzes[Object.keys(quizzes)[x]].quiz_url,
              points_possible: quizzes[Object.keys(quizzes)[x]].points_possible,
            })
          }

          let page_length_quiz = 0
          let perPage = 8
          if (quiz_body.length <= perPage) {
            page_length_quiz = 1
          } else {
            let num = quiz_body.length / perPage
            page_length_quiz = Math.ceil(num)
          }

          commit('FETCH_HOMEWORK_DATA', {
            homeworks: homework_body,
            quiz_body: quiz_body,
            page_length_quiz: page_length_quiz,
            subject_details: response.data.subjects[data.selected_subject],
            selected_subject: data.selected_subject,
          })
          if (quiz_body.length > 0) {
            var array = []
            var paginated_array = []
            for (var i = 0; i < 8; i++) {
              if (quiz_body[i]) {
                array.push(quiz_body[i])
              }
            }
            paginated_array = array
            var body = {
              subjects: {
                [data.selected_subject]: {
                  subject: data.selected_subject,
                  course_id:
                    response.data.subjects[data.selected_subject]
                      .revision_course_id,
                  quizzes: paginated_array,
                },
              },
            }
            dispatch('fetchQuizStatistics', {
              body: body,
              selected_subject: data.selected_subject,
            })
            dispatch('fetchCourseBadgesData', {
              studentid: data.studentid,
              course_id:
                response.data.subjects[data.selected_subject]
                  .revision_course_id,
            })
            dispatch('fetchCourseLeaderboardCached', {
              studentid: data.studentid,
              course_id:
                response.data.subjects[data.selected_subject]
                  .revision_course_id,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          }
        } else {
          commit('FETCH_SPP_DATA', response.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async fetchQuizStatistics({ commit }, data) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + Cookies.get('token')
    return await axios
      .post(spp_quiz_stats_api, data.body)
      .then((response) => {
        var quiz_scores = []
        for (
          var x = 0;
          x < data.body.subjects[data.selected_subject].quizzes.length;
          x++
        ) {
          var cas
          if (
            response.data.subjects[data.selected_subject].quiz[
              Object.keys(response.data.subjects[data.selected_subject].quiz)[x]
            ].cohort_average_score === null
          ) {
            cas = 0
          } else {
            var init_cas =
              response.data.subjects[data.selected_subject].quiz[
                Object.keys(response.data.subjects[data.selected_subject].quiz)[
                  x
                ]
              ].cohort_average_score
            var init_pp =
              data.body.subjects[data.selected_subject].quizzes[x]
                .points_possible
            cas = Math.round((init_cas / init_pp) * 100)
          }
          quiz_scores.push({
            ...data.body.subjects[data.selected_subject].quizzes[x],
            cohort_average_score: cas,
          })
        }
        commit('FETCH_QUIZ_STATISTICS', {
          quiz_scores: quiz_scores,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async fetchCourseBadgesData({ commit }, data) {
    try {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + Cookies.get('token')
      return await axios
        .get(`${spp_course_api}/${data.course_id}/badges/${data.studentid}`)
        .then((response) => {
          if (response.data.length > 0) {
            commit('FETCH_BADGE_DATA', {
              show_badges: true,
              badge_data: response.data,
            })
          } else {
            commit('FETCH_BADGE_DATA', {
              show_badges: false,
              badge_data: null,
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 500) {
            commit('FETCH_BADGE_DATA', {
              show_badges: false,
              badge_data: null,
            })
          }
        })
    } catch (error) {
      commit('FETCH_BADGE_DATA', {
        show_badges: false,
        badge_data: null,
      })
    }
  },
  async fetchCourseLeaderboardCached({ commit, dispatch }, data) {
    try {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + Cookies.get('token')
      return await axios
        .get(
          `${spp_course_api}/${data.course_id}/leaderboard-cache/${data.studentid}`,
        )
        .then((response) => {
          if (
            response.data.rankings !== undefined &&
            response.data.rankings.length > 0
          ) {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: true,
              leaderboard_data: response.data,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          } else {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: false,
              leaderboard_data: null,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          }
          dispatch('fetchCourseLeaderboard', {
            studentid: data.studentid,
            course_id: data.course_id,
            selected_subject: data.selected_subject,
            page_name: data.page_name,
          })
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 500) {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: false,
              leaderboard_data: null,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
            dispatch('fetchCourseLeaderboard', {
              studentid: data.studentid,
              course_id: data.course_id,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          }
        })
    } catch (error) {
      commit('FETCH_LEADER_BOARD', {
        show_leaderboard: false,
        leaderboard_data: null,
        selected_subject: data.selected_subject,
        page_name: data.page_name,
      })
      dispatch('fetchCourseLeaderboard', {
        studentid: data.studentid,
        course_id: data.course_id,
        selected_subject: data.selected_subject,
        page_name: data.page_name,
      })
    }
  },
  async fetchCourseLeaderboard({ commit }, data) {
    try {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + Cookies.get('token')
      return await axios
        .get(
          `${spp_course_api}/${data.course_id}/leaderboard/${data.studentid}`,
        )
        .then((response) => {
          if (
            response.data.rankings !== undefined &&
            response.data.rankings.length > 0
          ) {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: true,
              leaderboard_data: response.data,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          } else {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: false,
              leaderboard_data: null,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 500) {
            commit('FETCH_LEADER_BOARD', {
              show_leaderboard: false,
              leaderboard_data: null,
              selected_subject: data.selected_subject,
              page_name: data.page_name,
            })
          }
        })
    } catch (error) {
      commit('FETCH_LEADER_BOARD', {
        show_leaderboard: false,
        leaderboard_data: null,
        selected_subject: data.selected_subject,
        page_name: data.page_name,
      })
    }
  },
  async fetchStudentQuizStatistics({ commit }, data) {
    try {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + Cookies.get('token')
      return await axios
        .get(`${spp_data_api}/${data.studentid}/subject/${data.subject}`)
        .then((response) => {
          if (
            Object.keys(response.data.subjects)[0] !== undefined &&
            Object.keys(response.data.subjects)[0] === data.subject &&
            Object.keys(response.data.statistics.quiz_completion)[0] !==
              undefined &&
            Object.keys(response.data.statistics.quiz_completion)[0] ==
              data.subject
          ) {
            var quiz_stats =
              response.data.statistics.quiz_completion[data.subject]
            var quiz_data = response.data.subjects[data.subject].quiz

            const graded_quiz_data = Object.keys(quiz_data)
              .filter((key) => quiz_data[key].workflow_state === 'graded')
              .reduce((obj, key) => {
                obj[key] = quiz_data[key]
                return obj
              }, {})
            const unsubmitted_quiz_data = Object.keys(quiz_data)
              .filter((key) => quiz_data[key].workflow_state === 'unsubmitted')
              .reduce((obj, key) => {
                obj[key] = quiz_data[key]
                return obj
              }, {})

            commit('FETCH_STUDENT_QUIZ_STATISTICS', {
              show_side_bar: true,
              quiz_statistics: quiz_stats,
              graded_quiz_data: graded_quiz_data,
              unsubmitted_quiz_data: unsubmitted_quiz_data,
            })
          } else {
            commit('FETCH_STUDENT_QUIZ_STATISTICS', {
              show_side_bar: false,
              quiz_statistics: null,
              graded_quiz_data: null,
              unsubmitted_quiz_data: null,
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 500) {
            commit('FETCH_STUDENT_QUIZ_STATISTICS', {
              show_side_bar: false,
              quiz_statistics: null,
              graded_quiz_data: null,
              unsubmitted_quiz_data: null,
            })
          }
        })
    } catch (error) {
      commit('FETCH_STUDENT_QUIZ_STATISTICS', {
        show_side_bar: false,
        quiz_statistics: null,
        graded_quiz_data: null,
        unsubmitted_quiz_data: null,
      })
    }
  },
}

export { state, getters, actions, mutations }
